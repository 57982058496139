import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { store } from "../../redux/store";
import { setSelectedComponent, setSelectedLayout } from '../../redux/slices/layoutSlice';
import ProfileView from '../profile-view/profileView.component';

const ProfileWrapper = () => {
    const selectedView = useSelector(state => state.profile.selectedProfileView);
    const initLayout = () => {
        store.dispatch(setSelectedComponent('contact'));
        store.dispatch(setSelectedLayout('horizontal'));
    };
    useEffect(() => {
        initLayout();
    }, []);
    return <ProfileView selectedComponent={selectedView ? selectedView : 'profile'} />;
};

export default ProfileWrapper;
