import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { store } from "../../redux/store";
import { setSelectedComponent, setSelectedLayout } from '../../redux/slices/layoutSlice';
import GroupDetail from '../groups-view/group-detail/groupDetail.component';
import EmptyContent from '../empty-content/empty-content.component';

const GroupsWrapper = () => {
    const selectedGroup = useSelector(state => state.groups.selectedGroup);
    const initLayout = () => {
        store.dispatch(setSelectedComponent('groups'));
        store.dispatch(setSelectedLayout('horizontal'));
    };
    useEffect(() => {
        initLayout();
    }, []);
    return selectedGroup ? <GroupDetail group={selectedGroup} showHistory={true} /> : <EmptyContent view="groups" />;
};

export default GroupsWrapper;