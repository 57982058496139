const State = Object.freeze({
    OFFLINE: 'OFFLINE',
    ONLINE: 'ONLINE',
    BUSY: 'BUSY',
    LIVE: 'LIVE',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
});

export { State };
