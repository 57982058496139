import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import styles from './styles/history.module.scss';
import { getHistory } from "../../../services/VideoCallService";
import StateCallComponent from "../../state-call/stateCall.component";
import { StateCall } from "../../../utils/StateCallEnum";
import { getFormattedTimeFromLocalTime } from "../../../utils/date.util";
import { isToday } from 'date-fns';

class history extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            videoCallHistory: [],
            activeFilter: 'all',
        };
    }

    async componentDidMount() {
        const { contact } = this.props;
        if (contact && contact.id) {
            const videoCallHistory = await getHistory(contact.id);
            this.setState({ videoCallHistory });
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.contact.id !== this.props.contact.id) {
            const videoCallHistory = await getHistory(this.props.contact.id);
            this.setState({ videoCallHistory });
        }
    }

    toggleAccordion = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    };

    setFilter = (filter) => {
        this.setState({ activeFilter: filter });
    };

    formatCallDay = (timestamp, day, t) => {
        const date = new Date(timestamp);
        return isToday(date) ? <span className={styles.gray}>{this.calculateTimeDifference(timestamp, t)}</span> : <span>{day}</span>;
    };

    render() {
        const { t } = this.props;
        const { isOpen, videoCallHistory, activeFilter } = this.state;

        const filteredHistory = videoCallHistory.filter((call) => {
            if (activeFilter === 'missed') return call.state === StateCall.LOST;
            if (activeFilter === 'finished') return call.state === StateCall.COMPLETED;
            return true;
        });

        return (
            <div className={styles.container}>
                <div className={`${styles.accordion} ${isOpen ? styles.open : ''}`}>
                    <div className={styles.accordionHeader}>
                        <div onClick={this.toggleAccordion}>
                            <Icon icon={isOpen ? 'mingcute:down-line' : 'mingcute:up-line'} className={styles.dropdownIcon} width="20" height="20" />
                            <span className={styles.title}>
                                {t("ACCORDION-HISTORY.LABEL.Title")}
                                <span className={styles.count}>({filteredHistory.length})</span>
                            </span>
                        </div>
                        <div className={styles.historyFilters}>
                            <div onClick={() => this.setFilter('all')} className={activeFilter === 'all' ? styles.active : ''}>{t("ACCORDION-HISTORY.LABEL.all")}</div>
                            <div onClick={() => this.setFilter('missed')} className={activeFilter === 'missed' ? styles.active : ''}>{t("ACCORDION-HISTORY.LABEL.lost")}</div>
                            <div onClick={() => this.setFilter('finished')} className={activeFilter === 'finished' ? styles.active : ''}>{t("ACCORDION-HISTORY.LABEL.completed")}</div>
                        </div>
                    </div>
                    {isOpen && (
                        <div className={styles.accordionContent}>
                            {filteredHistory.length > 0 && (
                                filteredHistory.map((call, index) => (
                                    <div key={index} className={styles.row}>
                                        <div className={styles.state}><StateCallComponent state={call.state} /></div>
                                        <div>{call.state !== StateCall.LOST ? call.duration : ""}</div>
                                        <div className={styles.times}>
                                            <div>
                                                <span className={call.state === StateCall.LOST ? styles.red : styles.black}>
                                                    {getFormattedTimeFromLocalTime(call.time)}
                                                </span>
                                            </div>
                                            <div>{this.formatCallDay(call.time, call.day, t)}</div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslation()(history);
