import {store} from '../redux/store.js';
import {errorHandler} from '../utils/errorHandler.js';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const token = sessionStorage.getItem('accessToken');

const ContentService = {
    searchContent: async (term) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/content/search?term=' + term, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Content:', error);
            throw error;
        }
    },
    createContent: async (data) => {
        const tenantName = store.getState().tenant.tenant;
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('content', new Blob([JSON.stringify({
            resource: data.resource,
            name: data.name,
            description: data.description,
            active: data.status,
            categoryId: data.categoryId,
            subcategoryId: data.subcategoryId,
            creatorId: data.creationUserId,
            userIds: data.userIds,
            startDate: data.startDate,
            endDate: data.endDate,
            guestEmails: data.guestEmails
        })], { type: "application/json" }));

        try {
            const response = await fetch(`${API_URL}/api/v1/content`, {
                method: 'POST',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            errorHandler(response.status);

            if (response.ok) {
                return await response.json();
            } else {
                const errorResponse = await response.json();
                console.error('Error response:', errorResponse);
                throw new Error(`Error creating content: ${errorResponse.message}`);
            }
        } catch (error) {
            console.error('Error:', error.message);
            throw new Error('Error creating content');
        }
    },
    updateContent: async (data) => {
        const tenantName = store.getState().tenant.tenant;
        const formData = new FormData();

        if (data.file instanceof File) {
            formData.append('file', data.file);
        }

        const contentBlob = new Blob([JSON.stringify({
            id: data.id,
            resource: data.resource,
            name: data.name,
            description: data.description,
            active: data.status,
            categoryId: data.categoryId,
            subcategoryId: data.subcategoryId,
            creatorId: data.creationUserId,
            userIds: data.userIds,
            startDate: data.startDate,
            endDate: data.endDate,
            image: data.file instanceof File ? null : data.file,
            guestEmails: data.guestEmails
        })], { type: "application/json" });
        formData.append('content', contentBlob);

        try {
            const response = await fetch(API_URL + '/api/v1/content/' + data.id.toString(), {
                method: 'PUT',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            } else {
                const errorResponse = await response.json();
                console.error('Error response:', errorResponse);
                throw new Error(`Error updating content: ${errorResponse.message}`);
            }
            } catch (error) {
            console.error('Error fetching Content:', error);
            throw error;
        }
    },
    deleteContent: async (contentId) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/content/' + contentId.toString(), {
                method: 'DELETE',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
            });

            errorHandler(response.status);

            if (response.ok) {
                return;
            }
            return [];
        } catch (error) {
            console.error('Error fetching Content:', error);
            throw error;
        }
    },
    getCategories: async () => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/content/categories', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Content:', error);
            throw error;
        }
    },
    getSubcategories: async () => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/content/subcategories', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Content:', error);
            throw error;
        }
    },
    getContentById: async (contentId) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/content/' + contentId.toString(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Content:', error);
            throw error;
        }
    },
    getContentByUserAndDate: async (start, end) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(`${API_URL}/api/v1/content/events?start=${start.toISOString()}&end=${end.toISOString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Content by user and date:', error);
            throw error;
        }
    },
  patchContent: async (id, data) => {
    const tenantName = store.getState().tenant.tenant;
    const token = sessionStorage.getItem('accessToken');

    const response = await fetch(`${API_URL}/api/v1/content/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-Tenant-ID': tenantName,
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(`Error: ${response.status} - ${errorMessage}`);
    }

    return response.json();
  }
}

export const { searchContent, createContent, updateContent, deleteContent, getSubcategories, getCategories, getContentById, getContentByUserAndDate,patchContent } = ContentService;
