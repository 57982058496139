import React, { Component } from "react";
import NotificationCreateModal from "./notification-create-modal/notificationCreateModal.component";
import NotificationSelectUsers from "./notification-select-users/notificationSelectUsers.component";
import NotificationSelectHomes from "./notification-select-homes/notificationSelectHomes.component";
import { withTranslation } from "react-i18next";
import styles from "./styles/notificationCreate.module.scss";
import { createBulkNotifications } from '../../services/notification/NotificationService';

class NotificationCreate extends Component {
    state = {
        isCreateModalOpen: false,
        isSelectUsersModalOpen: false,
        isSelectHomesModalOpen: false,
        title: "",
        message: "",
        selectedUsers: [],
        selectedHomes: [],
    };

    openCreateModal = () => {
        this.setState({ isCreateModalOpen: true });
    };

    closeCreateModal = () => {
        this.setState({
            isCreateModalOpen: false,
            title: "",
            message: "",
            selectedUsers: [],
            selectedHomes: [],
        });
    };

    openSelectUsersModal = () => {
        this.setState({ isCreateModalOpen: false, isSelectUsersModalOpen: true });
    };

    closeSelectUsersModal = () => {
        this.setState({ isSelectUsersModalOpen: false, isCreateModalOpen: true });
    };

    openSelectHomesModal = () => {
        this.setState({ isCreateModalOpen: false, isSelectHomesModalOpen: true });
    };

    closeSelectHomesModal = () => {
        this.setState({ isSelectHomesModalOpen: false, isCreateModalOpen: true });
    };

    handleUsersSubmit = (selectedUsers) => {
        this.setState({
            selectedUsers,
            isSelectUsersModalOpen: false,
            isCreateModalOpen: true
        });
    };

    handleHomesSubmit = (selectedHomes) => {
        this.setState({
            selectedHomes,
            isSelectHomesModalOpen: false,
            isCreateModalOpen: true
        });
    };


    handleCreateSubmit = () => {
        const { title, message, selectedUsers, selectedHomes } = this.state;

        const notificationData = {
            title,
            body: message,
            userIds: selectedUsers,
            homeIds: selectedHomes,
        };

        try {
            createBulkNotifications(notificationData);
            console.log("Notification Submitted:", notificationData);
            this.closeCreateModal();
        } catch (error) {
            console.log(notificationData);
            console.error('Error submitting notification:', error);
        }
    };

    handleCreateInputChange = (name, value) => {
        this.setState({ [name]: value });
    };

    render() {
        const { isCreateModalOpen, isSelectUsersModalOpen, isSelectHomesModalOpen, selectedUsers, selectedHomes, title, message } = this.state;
        const { t } = this.props;
        return (
            <div>
                <button className={styles.createButton} onClick={this.openCreateModal}>
                    {t("NOTIFICATIONS.LABEL.CreateNotification")}
                </button>
                {isCreateModalOpen && (
                    <NotificationCreateModal
                        onSubmit={this.handleCreateSubmit}
                        onClose={this.closeCreateModal}
                        onOpenSelectUsers={this.openSelectUsersModal}
                        onOpenSelectHomes={this.openSelectHomesModal}
                        selectedUsers={selectedUsers}
                        selectedHomes={selectedHomes}
                        title={title}
                        message={message}
                        onInputChange={this.handleCreateInputChange}
                    />
                )}
                {isSelectUsersModalOpen && (
                    <NotificationSelectUsers
                        selectedUsers={selectedUsers}
                        onSubmit={this.handleUsersSubmit}
                        onClose={this.closeSelectUsersModal}
                    />
                )}
                {isSelectHomesModalOpen && (
                    <NotificationSelectHomes
                        selectedHomes={selectedHomes}
                        onSubmit={this.handleHomesSubmit}
                        onClose={this.closeSelectHomesModal}
                    />
                )}
            </div>
        );
    }
}

export default withTranslation()(NotificationCreate);
