import React, { Component } from "react";
import Avatar from "../../avatar/avatar.component";
import { withTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import styles from './styles/groupDetail.module.scss';
import { connect } from "react-redux";
import { setVideocallInfo } from "../../../redux/slices/videocallSlice";
import { setUserStatus } from "../../../redux/slices/userSlice";
import { changeUserStateToStartCall, changeUserStateToEndCall } from "../../../services/UserStateService";
import { useNavigate } from "react-router-dom";
import { callGroup } from "../../../services/GroupService";
import ConfirmModal from "../confirm-modal/confirmModal.component";
import { createCallGroup } from "../../../services/VideoCallService";
import { State } from "../../../utils/StateEnum";
import { getImage } from "../../../services/BlobService";
import { updateGroup } from "../../../redux/slices/groupSlice";

class GroupDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: null,
            showUserNotAvaliableModal: false,
            group: props.group,
            showCallModal: false,
            showInLiveCallModal: false,
            avatarImages: {},
        };
    }

    componentDidMount() {
        const { group } = this.props;
        if (group && group.members) {
            group.members.forEach(member => {
                if (member.avatar) {
                    getImage(member.avatar)
                        .then(response => {
                            const imageUrl = response;
                            this.setState(prevState => ({
                                avatarImages: {
                                    ...prevState.avatarImages,
                                    [member.email]: imageUrl
                                }
                            }));
                        })
                        .catch(error => {
                            console.error(`Error fetching image for member ${member.email}:`, error);
                        });
                }
            });
        }
    }

    componentWillUnmount() {
        const { avatarImages } = this.state;
        Object.values(avatarImages).forEach(imageUrl => {
            URL.revokeObjectURL(imageUrl);
        });
    }

    closeCallModal = () => {
        this.setState({ showCallModal: false });
    };

    closeInLiveCallModal = () => {
        this.setState({ showInLiveCallModal: false });
    };

    handleConfirmCall = async () => {
        const { group } = this.props;
        try {
            callGroup(group.id)
                .then(callResponse => {
                    console.log("[GROUPDETAIL] Call initiated:", callResponse);
                    this.props.setVideocallInfo(callResponse);
                    this.props.setUserStatus({ status: State.BUSY });
                    this.props.updateGroup({ group: { ...group, live: true } });
                    changeUserStateToStartCall(this.props.userId);
                    console.debug(callResponse);
                    this.props.navigate('/videocall');
                })
                .catch(error => {
                    console.error('[GROUPDETAIL] Error inicializando llamada', error);
                    this.props.setUserStatus({ status: State.ONLINE });
                    changeUserStateToEndCall(this.props.userId);
                });

            this.closeCallModal();
            this.closeInLiveCallModal();
        } catch (error) {
            console.error('[GROUPDETAIL] Error creating call:', error);
        }
    };

    handleConfirmInLiveCall = async () => {
        const { group } = this.props;
        try {
            const callerEmail = this.props.email;
            const users = group.members;
            const response = await createCallGroup(users, callerEmail);

            console.log('[GROUPDETAIL] Call initiated:', response);

            this.closeCallModal();
        } catch (error) {
            console.error('[GROUPDETAIL] Error creating call:', error);
        }
    };

    handleInitCall = () => {
        if (this.props.group.live) {
            this.setState({ showInLiveCallModal: true });
        } else {
            this.setState({ showCallModal: true });
        }
    };

    render() {
        const { group, t } = this.props;
        const { showCallModal, showInLiveCallModal, avatarImages } = this.state;
        const { members, memberColors, live } = group;
        const collageMembers = members.slice(0, 4);
        const isLive = live ? 'LIVE' : 'NOTLIVE';

        const collageImages = collageMembers.map((user, index) => {
            const initial = user.name
                ? user.name.charAt(0).toUpperCase()
                : user.email.charAt(0).toUpperCase();
            const backgroundColor = memberColors ? memberColors[user.email] : getRandomColor(index);
            return (
                <div
                    key={index}
                    className={styles.collageImage}
                    style={{ backgroundColor }}
                >
                    {initial}
                </div>
            );
        });

        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={`${styles.imageCollage} ${styles[`members-${collageMembers.length}`]}`}>
                        {collageImages}
                    </div>
                    <h1 className={styles.name}>{group.name}</h1>
                    <div className={styles.status}>
                        {live && <span className={styles.liveIndicator}></span>}
                        {t(`GROUPS.STATUS.${isLive}`)}
                    </div>
                    <button className={styles.videocallButton} onClick={this.handleInitCall}>
                        <Icon icon="fluent:video-24-filled" width="20" height="20" />
                        <span className={styles.videocallText}>{live ? t("GROUPS.DETAIL.JoinMeeting") : t("GROUPS.DETAIL.CreateMeeting")}</span>
                    </button>
                    <div className={styles.miscSection}>
                        <div className={styles.members}>
                            <h2>{t("GROUPS.DETAIL.MembersTitle")}</h2>
                            <ul
                                className={`${styles.membersList} ${members.length >= 3 ? styles.membersListThreeColumns : ''
                                    }`}
                            >
                                {members.map((member, index) => (
                                    <li key={index} className={styles.memberItem}>
                                        <Avatar
                                            image={avatarImages[member.email]}
                                            name={member.name}
                                            status={member.state}
                                            backgroundColor={memberColors ? memberColors[member.email] : getRandomColor(index)}
                                            size='avatar-medium'
                                        />
                                        <div className={styles.memberInfo}>
                                            <span className={styles.memberName}>{member.name}</span>
                                            <span className={styles.memberEmail}>{member.email}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                {showCallModal && (
                    <ConfirmModal
                        title={t('GROUPS.LABEL.CallGroup')}
                        message={t('GROUPS.LABEL.ConfirmCall')}
                        onConfirm={this.handleConfirmCall}
                        onCancel={this.closeCallModal}
                    />
                )}

                {showInLiveCallModal && (
                    <ConfirmModal
                        title={t('GROUPS.LABEL.InLiveCallGroup')}
                        message={t('GROUPS.LABEL.ConfirmInLiveCall')}
                        onConfirm={this.handleConfirmCall}
                        onCancel={this.closeInLiveCallModal}
                    />
                )}
            </div>
        );
    }
}

function getRandomColor(index) {
    const colors = ['#00B4E6', '#4169E1', '#1E90FF', '#5A9CFD', '#0F6BA8'];
    return colors[index % colors.length];
}

const mapStateToProps = (state) => ({
    group: state.groups.selectedGroup,
    userId: state.user.id,
});

const mapDispatchToProps = {
    setVideocallInfo,
    setUserStatus,
    updateGroup,
};

function GroupDetailWithNavigate(props) {
    const navigate = useNavigate();
    return <GroupDetail {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GroupDetailWithNavigate));
