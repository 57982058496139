import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import emptyNotification from '../../assets/img/emptyNotifications.svg';
import { withTranslation } from 'react-i18next';
import NotificationCard from "../notification-card/notificationCard.component";
import { Icon } from '@iconify/react';
import styles from "./styles/notification.module.scss";
import { connect } from 'react-redux';
import { getNotifications, markAllNotificationsAsRead, deleteAllNotifications, markNotificationAsRead, createBulkNotifications } from '../../services/notification/NotificationService';
import { setNotifications, markNotificationAsRead as markNotiAsRead } from '../../redux/slices/notificationSlice';
import NotificationDetail from '../notification-detail/notificationDetail.component';
import NotificationCreateModal from "../notification-create/notification-create-modal/notificationCreateModal.component";
import NotificationSelectUsers from "../notification-create/notification-select-users/notificationSelectUsers.component";
import NotificationSelectHomes from "../notification-create/notification-select-homes/notificationSelectHomes.component";
import NotificationDetailGroup from "../notification-detail-group/notificationDetailGroup.component";
import { hasAdminRole, hasProfesionalRole } from '../../utils/credentials.util';

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNotification: false,
            showDropdown: false,
            selectedNotification: null,
            isModalOpen: false,
            isCreateModalOpen: false,
            isSelectUsersModalOpen: false,
            isSelectHomesModalOpen: false,
            title: "",
            message: "",
            selectedUsers: [],
            selectedHomes: [],
            isModalOpenGroup: false,
        };
        this.notificationRef = React.createRef();
        this.dropdownRef = React.createRef();
    }

    async componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        await this.loadInitialNotifications();
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (
            this.notificationRef.current && !this.notificationRef.current.contains(event.target)
        ) {
            this.setState({ showNotification: false });
        }

        if (
            this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)
        ) {
            this.setState({ showDropdown: false });
        }
    };

    loadInitialNotifications = async () => {
        try {
            const notifications = await getNotifications(5, 0);
            this.props.setNotifications(notifications);
        } catch (error) {
            console.error('Error loading notifications:', error);
        }
    };

    toggleNotification = () => {
        this.setState((prevState) => ({
            showNotification: !prevState.showNotification
        }));
    };

    toggleDropdown = () => {
        this.setState((prevState) => ({
            showDropdown: !prevState.showDropdown
        }));
    };

    getUnreadNotificationCount = () => {
        return this.props.notifications.filter(notification => !notification.readed).length;
    };

    openNotificationDetail = async (notification) => {
        try {
            await markNotificationAsRead(notification.id);
            await this.props.markNotiAsRead(notification.id);
            if (notification.groupCallId) {
                this.setState({ selectedNotification: notification, isModalOpenGroup: true });
            } else {
                this.setState({ selectedNotification: notification, isModalOpen: true });
            }

        } catch (error) {
            console.error('Error al marcar la notificación como leída:', error);
        }
    };

    closeNotificationDetail = () => {
        this.setState({ isModalOpen: false, isModalOpenGroup: false });
    };

    markAllAsRead = async () => {
        try {
            await markAllNotificationsAsRead();
            console.log("Marked all as read");
            this.loadInitialNotifications();
            this.setState({ showDropdown: false });
        } catch (error) {
            console.error('Error marking all as read:', error);
        }
    };

    deleteAllNotifications = async () => {
        try {
            await deleteAllNotifications();
            console.log("Deleted all notifications");
            this.loadInitialNotifications();
            this.setState({ showDropdown: false });
        } catch (error) {
            console.error('Error deleting all notifications:', error);
        }
    };

    handleClickShowAll = () => {
        this.props.navigate('/notifications');
    };

    openCreateModal = () => {
        this.setState({ isCreateModalOpen: true });
    };

    closeCreateModal = () => {
        this.setState({
            isCreateModalOpen: false,
            title: "",
            message: "",
            selectedUsers: [],
            selectedHomes: [],
        });
    };

    openSelectUsersModal = () => {
        this.setState({ isCreateModalOpen: false, isSelectUsersModalOpen: true });
    };

    closeSelectUsersModal = () => {
        this.setState({ isSelectUsersModalOpen: false, isCreateModalOpen: true });
    };

    openSelectHomesModal = () => {
        this.setState({ isCreateModalOpen: false, isSelectHomesModalOpen: true });
    };

    closeSelectHomesModal = () => {
        this.setState({ isSelectHomesModalOpen: false, isCreateModalOpen: true });
    };

    handleUsersSubmit = (selectedUsers) => {
        this.setState({
            selectedUsers,
            isSelectUsersModalOpen: false,
            isCreateModalOpen: true
        });
    };

    handleHomesSubmit = (selectedHomes) => {
        this.setState({
            selectedHomes,
            isSelectHomesModalOpen: false,
            isCreateModalOpen: true
        });
    };

    handleCreateSubmit = async (formData) => {
        const { title, message, selectedUsers, selectedHomes } = this.state;

        const notificationData = {
            title,
            body: message,
            userIds: selectedUsers,
            homeIds: selectedHomes,
        };

        try {
            await createBulkNotifications(notificationData);
            console.log("Notification Submitted:", notificationData);
            this.closeCreateModal();
        } catch (error) {
            console.error('Error submitting notification:', error);
        }
    };

    handleCreateInputChange = (name, value) => {
        this.setState({ [name]: value });
    };

    render() {
        const { showNotification, showDropdown, selectedNotification, isModalOpen, isModalOpenGroup, isCreateModalOpen, isSelectUsersModalOpen, isSelectHomesModalOpen, selectedUsers, selectedHomes, title, message } = this.state;
        const { t, notifications } = this.props;
        const unreadCount = this.getUnreadNotificationCount();

        return (
            <div className={styles.notificationWrapper}>
                <div className={`${styles.notificationButton} ${showNotification ? styles.showNotification : ''}`}>
                    <div className={styles.notyIcon} onClick={this.toggleNotification}>
                        {unreadCount > 0 && <div className={styles.notyNum}>{unreadCount}</div>}
                        <Icon icon="fluent:alert-28-regular" width="25" height="25" />
                    </div>
                </div>

                {showNotification && (
                    <div className={styles.notificationContainer} ref={this.notificationRef}>

                        <section className={styles.notificationCard}>
                            <div className={styles.notificationsHeader}>
                                <h2>{t('NOTIFICATIONS.LABEL.Notifications')}</h2>
                                <div ref={this.dropdownRef} className={styles.optionsIcon}>
                                    <Icon icon="mi:options-vertical" width="25" height="25" onClick={this.toggleDropdown} />

                                    {showDropdown && (
                                        <div className={styles.dropdownMenu}>
                                            {(hasAdminRole() || hasProfesionalRole()) && (
                                                <button className={styles.newNotificationButton} onClick={this.openCreateModal}>
                                                    <Icon icon="material-symbols:notification-add-outline" className={styles.iconButton} width="20" height="20" />
                                                    {t("NOTIFICATIONS.LABEL.CreateNotification")}
                                                </button>
                                            )}
                                            <button onClick={this.markAllAsRead}>
                                                <Icon icon="bx:tag-alt" className={styles.iconButton} width="20" height="20" />
                                                {t("NOTIFICATIONS.LABEL.MarkAllAsRead")}
                                            </button>
                                            <button onClick={this.deleteAllNotifications}>
                                                <Icon icon="gravity-ui:trash-bin" className={styles.iconButton} width="20" height="20" />
                                                {t("NOTIFICATIONS.LABEL.DeleteAll")}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={styles.notificationsTabs}>
                                <div className={`${styles.tab} ${styles.activeTab}`}>
                                    <span className={styles.tabLabel}>{t('NOTIFICATIONS.LABEL.News')}</span>
                                    <div className={styles.tabUnderline} />
                                </div>
                                <div className={styles.tab}>
                                    {unreadCount > 0 && <div className={styles.tabBadge}>{unreadCount}</div>}
                                </div>
                                <div className={`${styles.tab} ${styles.inactiveTab}`} onClick={this.handleClickShowAll}>
                                    <span className={styles.tabLabel}>{t('NOTIFICATIONS.LABEL.ShowAll')}</span>
                                    <div className={styles.tabUnderline} />
                                </div>
                            </div>
                            {notifications.length === 0 ? (
                                <div className={styles.emptyNotifications}>
                                    <img src={emptyNotification} alt="" className={styles.notificationIcon} />
                                    <p className={styles.emptyNotificationMessage}>{t('NOTIFICATIONS.LABEL.EmptyNotifications')}</p>
                                </div>
                            ) : (
                                <>
                                    <div className={styles.notificationsContent}>
                                        {notifications.slice(0, 5).map((notification, index) => (
                                            <NotificationCard key={index} {...notification}
                                                onClick={() => this.openNotificationDetail(notification)} />
                                        ))}
                                    </div>
                                </>
                            )}
                        </section>
                    </div>
                )}

                {isCreateModalOpen && (
                    <NotificationCreateModal
                        onSubmit={this.handleCreateSubmit}
                        onClose={this.closeCreateModal}
                        onOpenSelectUsers={this.openSelectUsersModal}
                        onOpenSelectHomes={this.openSelectHomesModal}
                        selectedUsers={selectedUsers}
                        selectedHomes={selectedHomes}
                        title={title}
                        message={message}
                        onInputChange={this.handleCreateInputChange}
                    />
                )}

                {isSelectUsersModalOpen && (
                    <NotificationSelectUsers
                        selectedUsers={selectedUsers}
                        onSubmit={this.handleUsersSubmit}
                        onClose={this.closeSelectUsersModal}
                    />
                )}

                {isSelectHomesModalOpen && (
                    <NotificationSelectHomes
                        selectedHomes={selectedHomes}
                        onSubmit={this.handleHomesSubmit}
                        onClose={this.closeSelectHomesModal}
                    />
                )}

                {isModalOpen && (
                    <NotificationDetail
                        notification={selectedNotification}
                        onClose={this.closeNotificationDetail}
                    />
                )}

                {isModalOpenGroup && (
                    <NotificationDetailGroup
                        notification={selectedNotification}
                        onClose={this.closeNotificationDetail}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    notifications: state.notifications.notifications.slice(0, 5),
});

const mapDispatchToProps = {
    setNotifications,
    markNotiAsRead,
};
const NotificationsWrapper = (props) => {
    const navigate = useNavigate();
    return <Notifications {...props} navigate={navigate} />;
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NotificationsWrapper));
