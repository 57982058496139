import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearVideocallInfo } from '../../redux/slices/videocallSlice';
import { changeUserStateToEndCall } from '../../services/UserStateService.js';
import VideoCallJitsi from '../video-call-jitsi/videoCallJitsi.component';
import useBlockNavigation from '../../hooks/useBlockNavigation';
import ModalBlockNavigation from '../modal-block-navigation/modalBlockNavigation.component';
import { notifyVideoCallCancelation } from '../../services/VideoCallService';
import { changeLiveStatus } from '../../services/GroupService';
import { setSelectedGroup, updateGroup } from '../../redux/slices/groupSlice';
const VideoCallWrapper = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const videocallInfo = useSelector((state) => state.videocall.videocallInfo);
    const userId = useSelector((state) => state.user.id);
    const groups = useSelector((state) => state.groups.groups);
    const selectedGroup = useSelector((state) => state.groups.selectedGroup);
    const handleCallClosed = useCallback(() => {
        console.log('VIDEO CALL WRAPPER: Call closed', videocallInfo);
        const group = groups.find((group) => group.id === videocallInfo.groupCallId);
        if (videocallInfo.groupCallId !== null) {
            if(videocallInfo.nParticipants <= 2 || videocallInfo.nParticipants === undefined){
                changeLiveStatus(videocallInfo.groupCallId, false);
                console.log('VIDEO CALL WRAPPER: Changing live status to false on group call', group);
                dispatch(updateGroup({ group: { ...group, live: false } }));
                if (selectedGroup && selectedGroup.id === videocallInfo.groupCallId) {
                    dispatch(setSelectedGroup({ selectedGroup: { ...selectedGroup, live: false } }));
                }
            }
        } else {
            notifyVideoCallCancelation({...videocallInfo,token:undefined});
        }
        dispatch(clearVideocallInfo());
        changeUserStateToEndCall(userId);

    }, [dispatch, userId, videocallInfo, groups, selectedGroup]);

    const callClosed = useCallback(() => {
        handleCallClosed();
        navigate(-1);
    }, [handleCallClosed, navigate]);

    const { isModalVisible, handleCloseModal } = useBlockNavigation(!!videocallInfo, handleCallClosed);

    if (!videocallInfo) {
        return null;
    }

    return (
        <>
            <VideoCallJitsi
                appId={videocallInfo.appId}
                roomId={videocallInfo.roomId}
                token={videocallInfo.token}
                callClosed={callClosed}
            />
            {isModalVisible && (
                <ModalBlockNavigation
                    onClose={handleCloseModal}
                />
            )}
        </>
    );
};

export default VideoCallWrapper;
