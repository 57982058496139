import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './assets/styles/dashboard.module.scss';
import Header from '../../components/header/header.component';
import Menu from '../../components/menu/menu.component';
import { Action } from '../../utils/handleActionsEnum.js';
import { setSelectedComponent, setSelectedLayout } from '../../redux/slices/layoutSlice';
import { clearSelectedContact, setSelectedContact } from '../../redux/slices/contactSlice';
import { setSelectedProfileView } from '../../redux/slices/profileSlice';
import { clearVideocallInfo, setVideocallInfo } from '../../redux/slices/videocallSlice';
import { Topic } from '../../utils/TopicEnum.js';
import { connect } from 'react-redux';
import { setUserHomes, setUserStatus } from '../../redux/slices/userSlice';
import { setUserTypes } from '../../redux/slices/userTypeSlice';
import IncomingVideoCall from '../../components/video-call-view/video-call-incoming/incomingVideoCall.component.jsx';
import { playRingtone } from '../../utils/videocall.util.js';
import {
    getVideoCallCredentials, notifyVideoCallAcceptation,
    notifyVideoCallCancelation,
    notifyVideoCallRejection
} from '../../services/VideoCallService.js';
import { changeUserStateToEndCall, changeUserStateToStartCall } from '../../services/UserStateService.js';
import { Outlet, useNavigate } from 'react-router-dom';
import { store } from '../../redux/store';
import { getContacts } from '../../services/UserService.js';
import { getHomesByUser } from '../../services/HomeService.js';
import { setContacts } from '../../redux/slices/contactSlice.js';
import Avatar from "../../components/avatar/avatar.component";
import { getImage } from "../../services/BlobService";
import RejectedVideoCall from "../../components/video-call-view/video-call-rejected/rejectedVideoCall.component";
import { State } from "../../utils/StateEnum";
import { setGroups, updateGroup, addGroup, removeGroup, clearSelectedGroup } from '../../redux/slices/groupSlice.js';

const mapStateToProps = (state) => ({
    userEmail: state.user.email || "",
    userId: state.user.id,
    layout: state.layout.selectedLayout || 'horizontal',
    selectedComponent: state.layout.selectedComponent || 'contact',
    videocallInfo: state.videocall.videocallInfo || null,
    contacts: state.contact.contacts,
    groups: state.groups.groups,
    userState: state.user.userStatus,
});

const mapDispatchToProps = (dispatch) => ({
    setUserHomes: (homes) => dispatch(setUserHomes({ homes })),
    setUserTypes: (userTypes) => dispatch(setUserTypes({ userTypes })),
    setSelectedContact: (contact) => dispatch(setSelectedContact(contact)),
    clearSelectedContact: () => dispatch(clearSelectedContact()),
    setSelectedProfileView: (view) => dispatch(setSelectedProfileView(view)),
    setVideocallInfo: (videocall) => dispatch(setVideocallInfo(videocall)),
    clearVideocallInfo: () => dispatch(clearVideocallInfo()),
    setSelectedLayout: (layout) => dispatch(setSelectedLayout(layout)),
    setSelectedComponent: (component) => dispatch(setSelectedComponent(component)),
    setContacts: (contacts) => dispatch(setContacts(contacts)),
    setUserStatus: (status) => dispatch(setUserStatus(status)),
    setGroups: (groups) => dispatch(setGroups(groups)),
    updateGroup: (groups) => dispatch(updateGroup(groups)),
    addGroup: (groups) => dispatch(addGroup(groups)),
    removeGroup: (groups) => dispatch(removeGroup(groups)),
    clearSelectedGroup: () => dispatch(clearSelectedGroup())
});


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout: this.props.layout,
            selectedMenuComponent: this.props.selectedComponent,
            showIncomingVideoCall: false,
            showRejectedVideoCall: false,
            videocallSubscription: null,
            stateSubscription: null,
            groupSubscription: null,
            rejectingUser: {},
            callbackRegistry: {},
            worker: null
        };

        this.subscribeToRabbitTopic = this.subscribeToRabbitTopic.bind(this);
    }

    registerCallback(id, callback) {
        this.setState((prevState) => ({
            callbackRegistry: {
                ...prevState.callbackRegistry,
                [id]: callback
            }
        }));
    }

    getCallback(id) {
        return this.state.callbackRegistry[id];
    }



    async subscribeToRabbitTopic() {
        if (!this.state.worker) {
            console.log('Solicitando conexión al WebSocket...');
            const worker = new Worker(new URL('../../worker/worker.js', import.meta.url));

            worker.onmessage = (event) => {
                const { type, destination, data, callbackId } = event.data;
                if (type === 'MESSAGE') {
                    console.log(`Mensaje recibido del tópico ${destination}:`, data);
                    const callback = this.getCallback(callbackId);
                    if (callback) {
                        callback(data);
                    } else {
                        console.error(`No se encontró un callback para el ID: ${callbackId}`);
                    }
                }
            };

            await this.setStateAsync({ worker });

            const tenant = store.getState().tenant.tenant;
            let data = {
                userId: this.props.userId,
                tenant: tenant,
                userState: this.props.userState,
            };

            this.state.worker.postMessage({ type: 'CONNECT', data: data });
            this.state.worker.postMessage({ type: 'START_PING', data: data });

            this.registerCallback('contactStatusUpdated', this.contactStatusUpdatedEventListener.bind(this));
            this.registerCallback('groupStatusUpdated', this.groupStatusUpdatedEventListener.bind(this));
            this.registerCallback('videoCallEvent', this.videoCallEventListener.bind(this));

            this.subscribeToTopics(tenant);
        } else {
            const tenant = store.getState().tenant.tenant;
            let data = {
                userId: this.props.userId,
                tenant: tenant,
                userState: this.props.userState,
            };

            this.state.worker.postMessage({ type: 'CONNECT', data: data });
            this.state.worker.postMessage({ type: 'START_PING', data: data });
            this.subscribeToTopics(tenant);
        }
    }

    // Promesa para esperar a que se actualice el estado
    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    subscribeToTopics(tenant) {
        console.log('Conexión WebSocket exitosa. Suscribiendo al topic state...');
        this.state.worker.postMessage({ type: 'SUBSCRIBE', topic: Topic.STATE(tenant), callbackId: 'contactStatusUpdated' });

        console.log('Conexión WebSocket exitosa. Suscribiendo al topic group...');
        this.state.worker.postMessage({ type: 'SUBSCRIBE', topic: Topic.GROUP(tenant), callbackId: 'groupStatusUpdated' });

        console.log('Conexión WebSocket exitosa. Suscribiendo al topic videocall...');
        this.state.worker.postMessage({ type: 'SUBSCRIBE', topic: Topic.VIDEOCALL(tenant), callbackId: 'videoCallEvent' });
    }

    contactStatusUpdatedEventListener(message) {
        try {
            const parsedMessage = JSON.parse(message.body);
            console.debug('[MESSAGE RECEIVED]', parsedMessage);
            const contacts = this.props.contacts;

            const updatedContacts = contacts.map(contact => {
                if (contact.id === parsedMessage.id) {
                    return { ...contact, state: parsedMessage.state };
                }
                return contact;
            });

            if (JSON.stringify(updatedContacts) !== JSON.stringify(contacts)) {
                this.props.setContacts(updatedContacts);
            } else if (this.props.userId === parsedMessage.id) {
                this.props.setUserStatus({ status: parsedMessage.state });
            } else {
                console.debug(`No se encontró ningún contacto con el ID ${parsedMessage.id}`);
            }
        } catch (error) {
            console.error('Error al analizar el mensaje:', error);
        }
    }

    groupStatusUpdatedEventListener(message) {
        try {
            const parsedMessage = JSON.parse(message.body);
            console.debug('[MESSAGE RECEIVED]', parsedMessage);

            const group = this.props.groups.find(group => group.id === parsedMessage.id);

            if (group) {
                if (parsedMessage.state === State.ACTIVE || parsedMessage.state === State.LIVE) {
                    this.props.updateGroup({ group: parsedMessage.group });
                }

                if (parsedMessage.state === State.INACTIVE) {
                    this.props.removeGroup({ groupId: group.id });
                }
            } else {
                const userId = this.props.userId;
                const isMember = parsedMessage.group.members.some(member => member.id === userId);

                if (isMember) {
                    console.debug(`El usuario con ID ${userId} es miembro del grupo ${parsedMessage.group.id}, agregando el grupo.`);
                    this.props.addGroup({ group: parsedMessage.group });
                } else {
                    console.debug(`No se encontró ningún grupo con el ID ${parsedMessage.id}, y el usuario ${userId} no es miembro.`);
                }
            }
        } catch (error) {
            console.error('Error al analizar el mensaje:', error);
        }
    }

    videoCallEventListener(message) {
        const videoCall = JSON.parse(message.body);
        console.debug('[MESSAGE RECEIVED]', videoCall);

        if (videoCall.type === 'REJECTED_CALL') {
            if (this.props.videocallInfo && this.props.videocallInfo.roomId === videoCall.roomId) {
                if (videoCall.rejectingUser.email === this.props.userEmail) {
                    console.log('Llamada rechazada por el usuario actual.');
                    playRingtone(false);
                    this.setState({ showIncomingVideoCall: false });
                } else if (videoCall.groupCallId !== null) {
                    console.log("Llamada rechazada en un grupo, igonorando");
                } else if (videoCall.user.email === this.props.userEmail) {
                    console.log('Llamada rechazada por el usuario:', videoCall.rejectingUser);
                    this.setState({ rejectingUser: videoCall.rejectingUser }, () => {
                        playRingtone(false);
                        this.props.clearVideocallInfo();
                        this.props.navigate(-1);
                        this.setState({ showRejectedVideoCall: true });
                        changeUserStateToEndCall(this.props.userId);
                    });

                }
            }
        } else if (videoCall.type === 'NEW_CALL') {
            console.log(`NEW_CALL recibido. Actual: ${this.props.userEmail}. Remitente: ${videoCall.user.email}. Destinatarios (${videoCall.recipients?.length}): ${videoCall.recipients?.[0].email}`);
            if (store.getState().user.userStatus !== State.BUSY && videoCall.user.email !== this.props.userEmail && videoCall.recipients?.some(recipient => recipient.email === this.props.userEmail)) {
                console.log('Recibiendo nueva llamada entrante');
                changeUserStateToStartCall(this.props.userId);
                this.props.setVideocallInfo(videoCall);
                this.setState({ showIncomingVideoCall: true });
            }
        } else if (videoCall.type === 'ACCEPTED_CALL') {
            if (this.props.videocallInfo && this.props.videocallInfo.roomId === videoCall.roomId) {
                if (videoCall.user.email === this.props.userEmail) {
                    if (!this.state.callAlreadyAccepted) {
                        console.log('Llamada aceptada, manejando la aceptación');
                        this.setState({ callAlreadyAccepted: true });
                        //this.acceptedOutcomingVideoCall();
                    } else {
                        console.log('Llamada ya aceptada, evitando duplicados.');
                    }
                } else if (videoCall.acceptingUser.email === this.props.userEmail) {
                    console.log('Llamada aceptada por el usuario:', videoCall.acceptingUser);
                    this.setState({ showIncomingVideoCall: false });
                    playRingtone(false);
                }
            }
        } else if (videoCall.type === 'CANCELLED_CALL') {
            if (this.props.videocallInfo && this.props.videocallInfo.roomId === videoCall.roomId && videoCall.groupCallId === null) {
                console.log('Llamada cancelada, manejando la cancelación');
                this.cancelledIncomingVideoCall();
            } else {
                console.log('Alguien salió de la llamada grupal.');
            }
        }
    }

    handleCallEnd = () => {
        console.log('La llamada ha terminado.');
        this.endCall();
    };

    async componentDidMount() {
        console.log("componentDidMount ejecutado en Dashboard");

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            console.log("getUserMedia está disponible.");
        } else {
            console.error("getUserMedia no está disponible.");
        }
        const response = await getContacts(false);
        this.props.setContacts(response);

        const homes = await getHomesByUser();
        this.props.setUserHomes(homes);
        await this.subscribeToRabbitTopic();
    }

    componentWillUnmount() {
        console.log("Dashboard desmontado");
        if (this.state.stateSubscription) {
            this.state.stateSubscription.unsubscribe();
            this.setState({ stateSubscription: null });
        }
        if (this.state.videocallSubscription) {
            this.state.videocallSubscription.unsubscribe();
            this.setState({ videocallSubscription: null });
        }
    }

    handleActionClick = (action, object) => {
        console.log(action, object);
        switch (action) {
            case Action.CLEAN:
                this.props.clearSelectedContact();
                this.props.clearSelectedGroup();
                break;
            case Action.CONTACTS:
                this.props.clearSelectedContact();
                this.props.clearSelectedGroup();
                this.props.navigate('/contacts');
                break;
            case Action.GROUPS:
                this.props.clearSelectedContact();
                this.props.clearSelectedGroup();
                this.props.navigate('/groups');
                break;
            case Action.USER_DETAIL:
                this.props.setSelectedContact(object);
                this.props.clearSelectedGroup();
                this.props.navigate('/contacts');
                break;
            case Action.USER_DETAIL_HISTORY:
                this.props.setSelectedContact(object);
                this.props.clearSelectedGroup();
                this.props.navigate('/groups');
                break;
            case Action.HOMES:
                this.props.clearSelectedContact();
                this.props.clearSelectedGroup();
                this.props.navigate('/homes');
                break;
            case Action.CONTENT:
                this.props.clearSelectedContact();
                this.props.clearSelectedGroup();
                this.props.navigate('/content');
                break;
            case Action.USERS:
                this.props.clearSelectedContact();
                this.props.clearSelectedGroup();
                this.props.navigate('/users');
                break;
            case Action.CALENDAR:
                this.props.clearSelectedContact();
                this.props.clearSelectedGroup();
                this.props.navigate('/calendar');
                break;
            case Action.PROFILE:
                this.props.clearSelectedContact();
                this.props.clearSelectedGroup();
                this.props.setSelectedProfileView(object);
                this.props.navigate('/profile');
                break;
            default:
                this.props.clearSelectedContact();
                this.props.clearSelectedGroup();
                this.props.navigate('/contacts');
                break;
        }
    }

    closeIncomingVideoCallModal = () => {
        playRingtone(false);
        this.setState({ showIncomingVideoCall: false });
        notifyVideoCallRejection(this.props.videocallInfo);
        this.props.clearVideocallInfo();
        changeUserStateToEndCall(this.props.userId);
    }
    cancelOutcomingVideoCallModal = () => {
        playRingtone(false);
        notifyVideoCallCancelation(this.props.videocallInfo);
        this.setState({ showOutcomingVideoCall: false });
        changeUserStateToEndCall(this.props.userId);
        this.props.clearVideocallInfo();
    }
    cancelledIncomingVideoCall = () => {
        let userId = this.props.userId;
        playRingtone(false);
        this.setState({ showIncomingVideoCall: false }, () => {
            changeUserStateToEndCall(userId);
            this.props.clearVideocallInfo();
        });
    }

    acceptedOutcomingVideoCall = () => {
        playRingtone(false);
        //notifyVideoCallAcceptation(this.props.videocallInfo);
        changeUserStateToStartCall(this.props.userId);
        this.setState({ showOutcomingVideoCall: false });
        this.props.navigate('/videocall');
    }

    acceptIncomingVideoCall = () => {
        playRingtone(false);
        getVideoCallCredentials(this.props.videocallInfo.roomId).then(credentials => {
            this.props.setVideocallInfo({
                ...this.props.videocallInfo,
                token: credentials.token,
                appId: credentials.appId
            });
            notifyVideoCallAcceptation(this.props.videocallInfo);
            changeUserStateToStartCall(this.props.userId);
            this.setState({ showIncomingVideoCall: false });
            this.props.navigate('/videocall');
        });
    }

    closeRejectedVideoCall = () => {
        this.setState({ showRejectedVideoCall: false });
    }

    handleIconClick = (view, object) => {
        if (view === 'profile') {
            this.handleActionClick(Action.CONTACTS);
            setTimeout(() => {
                this.handleActionClick(Action.PROFILE, object);
            }, 0);
        } else {
            this.handleActionClick(view.toUpperCase(), object);
        }
    };

    async fetchImage(imageId) {
        if (imageId) {
            try {
                return await getImage(imageId);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        }
    };

    render() {
        const { selectedMenuComponent, showIncomingVideoCall, showRejectedVideoCall } = this.state;
        const { t, videocallInfo, userId } = this.props;

        if (!userId) {
            return null;
        }

        return (
            <>
                <Header onIconClick={this.handleIconClick} selectedComponent={selectedMenuComponent} />
                <div className={`${styles.dashboardContainer} ${styles[this.props.layout]}`}>
                    <div className={`${styles.menuContainer} ${styles[this.props.layout]}`}>
                        <Menu
                            handleActionClick={this.handleActionClick}
                            onLayoutChange={this.handleLayoutChange}
                            selectedComponent={selectedMenuComponent}
                        />
                    </div>
                    <div className={styles.mainContainer}>
                        <Outlet />
                    </div>
                </div>
                {showIncomingVideoCall &&
                    <IncomingVideoCall
                        children={t("VIDEOCALL.INCOMING.Message")}
                        avatar={
                            <Avatar image={this.fetchImage(videocallInfo.user?.avatar)}
                                name={videocallInfo.user?.alias}
                                backgroundColor={videocallInfo.user?.backgroundColor}
                                size='avatar-hard'
                            />
                        }
                        onClose={this.closeIncomingVideoCallModal}
                        onAccept={this.acceptIncomingVideoCall}
                        sender={videocallInfo.user}
                    />}

                {showRejectedVideoCall && <RejectedVideoCall
                    children={t("VIDEOCALL.REJECTED.Message")}
                    rejectingUser={this.state.rejectingUser}
                    onClose={this.closeRejectedVideoCall}
                    buttons={
                        [{
                            label: t("CONTACT_DETAIL.BUTTON.success"),
                            className: styles.modalAcceptButton,
                            onClick: this.closeRejectedVideoCall
                        }]
                    }
                />}
            </>
        );
    }
}

function DashboardWithNavigate(props) {
    const navigate = useNavigate();
    return <Dashboard {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardWithNavigate));
