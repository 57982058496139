import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import UploadFileModal from '../../upload-file-modal/uploadFileModal.component';
import { getCategories, getSubcategories, createContent } from '../../../services/ContentService';
import { searchUsers } from '../../../services/UserService';
import DropdownSelect from '../../dropdown-select/dropdownSelect.component';
import DateTimeInput from '../../date-time-input/dateTimeInput.component';
import CustomInput from '../../custom-input/customInput.component';
import ModalFeedback from '../../modal-feedback/modalFeedback.component';
import LoadingScreen from '../../loading-screen/loadingScreen.component';
import SelectButton from "../../notification-create/select-button/selectButton.component";
import ModalInviteGroup from "../../groups-view/create-group/modal-invite-group/modalInviteGroup.component";
import HomeUserTreeModal from "../../content-view/home-user-tree-modal/homeUserTreeModal.component";
import styles from './styles/createContent.module.scss';
import greyImage from '../../../assets/img/imagegrey.png';
import blueImage from '../../../assets/img/imageblue.png';

class CreateContent extends Component {
  state = {
    contentData: {
      resource: '',
      name: '',
      description: '',
      startDate: '',
      endDate: ''
    },
    isResourceAutoGenerated: false,
    categories: [],
    subcategories: [],
    creationUsers: [],
    selectedCategory: null,
    selectedSubcategory: null,
    selectedCreationUser: null,
    selectedStatus: { key: true, value: this.props.t("CONTENT.CREATE.Activated") },
    imageSrc: greyImage,
    isUploadModalOpen: false,
    isFeedbackModalOpen: false,
    feedbackType: null,
    feedbackMessage: '',
    selectedFile: null,
    errors: {},
    validate: false,
    isLoading: false,
    guestEmails: [],
    isInviteModalOpen: false,
    isHomeUserTreeModalOpen: false,
    selectedUsers: new Set(),
  };

  async componentDidMount() {
    try {
      const categories = await getCategories();
      this.setState({ categories: categories.map(cat => ({ key: cat.id, value: cat.name })) });

      const subcategories = await getSubcategories();
      this.setState({ subcategories: subcategories.map(sub => ({ key: sub.id, value: sub.name })) });

      const creationUsers = await this.searchUsers("");
      this.setState({ creationUsers });

    } catch (error) {
      console.error('Error obteniendo categorías, subcategorías o usuarios:', error);
    }
  }

  validateFields = () => {
    const { contentData, isResourceAutoGenerated, selectedCategory, selectedCreationUser, selectedUsers, guestEmails } = this.state;
    const errors = {};
  
    if (!contentData.name.trim()) {
      errors.name = this.props.t("CONTENT.CREATE.ValidationRequired");
    }
    if (!selectedCreationUser) {
      errors.selectedCreationUser = this.props.t("CONTENT.CREATE.ValidationRequired");
    }
    if (!selectedCategory || selectedCategory.length === 0) {
      errors.selectedCategory = this.props.t("CONTENT.CREATE.ValidationRequired");
    }
    if (selectedUsers.size === 0 && guestEmails.length === 0) {
      errors.selectedUsers = this.props.t("CONTENT.CREATE.ValidationRequired");
    }
    if (!isResourceAutoGenerated && !contentData.resource.trim()) {
      errors.resource = this.props.t("CONTENT.CREATE.ValidationRequired");
    }

    this.setState({ errors, validate: true });

    return Object.keys(errors).length > 0;
  }

  handleSave = async () => {
    const { contentData, isResourceAutoGenerated } = this.state;
    const hasErrors = this.validateFields();

    if (hasErrors) {
      console.log('Error de validación:', this.state.errors);
      return;
    }

    const {
      selectedCategory,
      selectedSubcategory,
      selectedUsers,
      selectedCreationUser,
      selectedStatus,
      selectedFile,
      guestEmails
    } = this.state;

    const data = {
      file: selectedFile,
      resource: isResourceAutoGenerated ? null : contentData.resource,
      name: contentData.name,
      description: contentData.description,
      status: selectedStatus.key,
      categoryId: selectedCategory?.[0]?.key || null,
      subcategoryId: selectedSubcategory?.[0]?.key || null,
      creationUserId: selectedCreationUser?.key || null,
      userIds: Array.from(selectedUsers),
      guestEmails,
      startDate: contentData.startDate,
      endDate: contentData.endDate
    };

    this.setState({ isLoading: true });

    try {
      await createContent(data);
      this.props.onClose(true);
    } catch (error) {
      console.error('Error creando contenido:', error);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'error',
        feedbackMessage: this.props.t("CONTENT.CREATE.ErrorMessage"),
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  openInviteModal = () => {
    this.setState({ isInviteModalOpen: true });
  };

  closeInviteModal = () => {
    this.setState({ isInviteModalOpen: false });
  };

  handleInviteSubmit = (guestEmails) => {
    this.setState({ guestEmails, isInviteModalOpen: false });
  };

  setSelectedCategory = (selectedOptions) => {
    const categories = Array.isArray(selectedOptions) ? selectedOptions : [];
    const isResourceAuto = categories.some(cat => cat.value === "Actividades_Grupo");

    this.setState((prevState) => ({
      selectedCategory: categories,
      isResourceAutoGenerated: isResourceAuto,
      contentData: {
        ...prevState.contentData,
        resource: isResourceAuto ? this.props.t("CONTENT.LABEL.Autogenerated") : ''
      }
    }));
  }

  setSelectedSubcategory = (selectedOptions) => {
    this.setState({ selectedSubcategory: Array.isArray(selectedOptions) ? selectedOptions : [] });
  }

  setSelectedCreationUser = (selectedOptions) => {
    this.setState({ selectedCreationUser: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  }

  setSelectedStatus = (selectedOptions) => {
    this.setState({ selectedStatus: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  }

  searchUsers = async (term) => {
    try {
      let users = await searchUsers(term);
      users = users.filter(user => user.userType.name === "Admin" || user.userType.name === "Professional");
      return users.map(user => ({ key: user.id, value: user.email }));
    } catch (error) {
      console.error('Error buscando usuarios:', error);
      return [];
    }
  }

  handleInputChange = (name, value) => {
    if (name === 'resource' && this.state.isResourceAutoGenerated) {
      return;
    }

    this.setState((prevState) => ({
      contentData: {
        ...prevState.contentData,
        [name]: value
      },
      errors: {
        ...prevState.errors,
        [name]: null
      }
    }));
  }

  handleDateChange = (dateType, dateValue) => {
    this.setState((prevState) => ({
      contentData: {
        ...prevState.contentData,
        [dateType]: dateValue
      }
    }));
  }

  handleImageMouseEnter = () => {
    if (this.state.selectedFile) return;
    this.setState({ imageSrc: blueImage });
  };

  handleImageMouseLeave = () => {
    if (this.state.selectedFile) return;
    this.setState({ imageSrc: greyImage });
  };

  handleImageClick = () => {
    this.setState({ isUploadModalOpen: true });
  };

  handleEditClick = () => {
    this.setState({ isUploadModalOpen: true });
  };

  closeUploadModal = () => {
    this.setState({ isUploadModalOpen: false });
  };

  handleFileSelect = (file) => {
    this.setState({ selectedFile: file, imageSrc: URL.createObjectURL(file) });
  };

  closeFeedbackModal = () => {
    this.setState({ isFeedbackModalOpen: false, feedbackMessage: '', feedbackType: null });
  };

  openHomeUserTreeModal = () => {
    this.setState({ isHomeUserTreeModalOpen: true });
  };

  submitHomeUserTreeModal = (selectedUsers) => {
    this.setState({
      isHomeUserTreeModalOpen: false,
      selectedUsers: new Set(selectedUsers),
      errors: {
        ...this.state.errors,
        selectedUsers: null,
      }
    });
  };
  closeHomeUserTreeModal = () => {
    this.setState({ isHomeUserTreeModalOpen: false });
  };

  render() {
    const {
      categories,
      subcategories,
      creationUsers,
      selectedCreationUser,
      selectedStatus,
      contentData,
      imageSrc,
      isUploadModalOpen,
      isFeedbackModalOpen,
      feedbackType,
      feedbackMessage,
      validate,
      isLoading,
      isResourceAutoGenerated,
      guestEmails,
      isInviteModalOpen,
      errors,
      isHomeUserTreeModalOpen,
      selectedUsers,
    } = this.state;
    const { t } = this.props;
    const buttons = [
      { label: t("CONTENT.CREATE.SaveButton"), className: styles.saveButton, onClick: this.handleSave },
      { label: t("CONTENT.CREATE.CancelButton"), className: styles.cancelButton, onClick: () => this.props.onClose(false) }
    ];

    const statusOptions = [
      { key: true, value: t("CONTENT.CREATE.Activated") },
      { key: false, value: t("CONTENT.CREATE.Deactivated") }
    ];

    return (
      <>
        {isLoading && <LoadingScreen />}
        <Modal title={t("CONTENT.CREATE.Title")} titleClassName={styles.modalTitle} buttons={buttons}>
          <div className={styles.formContent}>
            <div className={styles.row}>
              <div className={styles.imageFormHeader}>
                <label className={styles.label}>{t("CONTENT.CREATE.Image")}</label>
                <span className={styles.editLabel} onClick={this.handleEditClick}>{t("CONTENT.CREATE.editImage")}</span>
              </div>
              <img
                src={imageSrc}
                alt=""
                className={styles.image}
                onMouseEnter={this.handleImageMouseEnter}
                onMouseLeave={this.handleImageMouseLeave}
                onClick={this.handleImageClick}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.Resource")}{!isResourceAutoGenerated && <span className={styles.requiredMark}> *</span>}</label>
              <CustomInput
                type="text"
                name="resource"
                value={isResourceAutoGenerated ? t("CONTENT.LABEL.Autogenerated") : contentData.resource}
                onChange={(value) => this.handleInputChange('resource', value)}
                placeholder={t("CONTENT.CREATE.ResourcePlaceholder")}
                customClass={styles.inputField}
                validate={validate}
                errorMessage={errors.resource}
                disabled={isResourceAutoGenerated}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.Name")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="name"
                value={contentData.name}
                onChange={(value) => this.handleInputChange('name', value)}
                placeholder={t("CONTENT.CREATE.NamePlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("CONTENT.CREATE.ValidationRequired") }]}
                validate={validate}
                errorMessage={errors.name}
              />
              <label className={styles.label}>{t("CONTENT.CREATE.Description")}</label>
              <CustomInput
                type="text"
                name="description"
                value={contentData.description}
                onChange={(value) => this.handleInputChange('description', value)}
                placeholder={t("CONTENT.CREATE.DescriptionPlaceholder")}
                customClass={styles.inputField}
                validate={validate}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.CreationUser")}<span className={styles.requiredMark}> *</span></label>
              <DropdownSelect
                options={creationUsers}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectCreationUserPlaceholder")}
                setSelectedOptions={this.setSelectedCreationUser}
                searchable={true}
                search={this.searchUsers}
                selectedOptions={selectedCreationUser ? [selectedCreationUser] : []}
                customClass={styles.selectCreationUser}
                required={true}
                validate={validate}
                errorMessage={errors.selectedCreationUser}
              />
              <label className={styles.label}>{t("CONTENT.CREATE.Category")}<span className={styles.requiredMark}> *</span></label>
              <DropdownSelect
                options={categories}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectCategoryPlaceholder")}
                setSelectedOptions={this.setSelectedCategory}
                customClass={styles.selectCategory}
                required={true}
                validate={validate}
                errorMessage={errors.selectedCategory}
              />
              <label className={styles.label}>{t("CONTENT.CREATE.Subcategory")}</label>
              <DropdownSelect
                options={subcategories}
                isMultiple={false}
                placeholder={t("CONTENT.CREATE.SelectSubcategoryPlaceholder")}
                setSelectedOptions={this.setSelectedSubcategory}
                customClass={styles.selectSubcategory}
              />
            </div>
            <div className={`${styles.row} ${styles.spaceBetween}`}>
              <div className={styles.statusGroup}>
                <label className={styles.label}>{t("CONTENT.CREATE.Status")}<span className={styles.requiredMark}> *</span></label>
                <DropdownSelect
                  options={statusOptions}
                  isMultiple={false}
                  placeholder={t("CONTENT.CREATE.SelectStatusPlaceholder")}
                  setSelectedOptions={this.setSelectedStatus}
                  selectedOptions={[selectedStatus]}
                  customClass={styles.selectStatus}
                  required={true}
                  validate={validate}
                />
              </div>
              <div className={styles.selectUsersGroup}>
                <label className={styles.label}>{t("CONTENT.CREATE.SelectHomesAndUsers")}<span className={styles.requiredMark}> *</span></label>
                <div className={styles.selectUsersButton}>
                  <SelectButton
                    icon="uit:house-user"
                    label={t("CONTENT.CREATE.SelectHomesAndUsersButton")}
                    selectedCount={selectedUsers.size}
                    onClick={this.openHomeUserTreeModal}
                    validate={validate}
                  />
                </div>
                {isResourceAutoGenerated && (
                  <div className={styles.buttonGroup}>
                    <SelectButton
                      icon="ph:envelope-thin"
                      label={t("GROUPS.LABEL.AddGuestEmailsButton")}
                      selectedCount={guestEmails.length}
                      onClick={this.openInviteModal}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={styles.row}>
              <label className={styles.label}>{t("CONTENT.CREATE.Date")}</label>
              <DateTimeInput
                label={t("CONTENT.CREATE.StartDate")}
                customClass={styles.dateClass}
                value={contentData.startDate}
                onChange={(date) => this.handleDateChange('startDate', date)}
              />
              <DateTimeInput
                label={t("CONTENT.CREATE.EndDate")}
                customClass={styles.dateClass}
                value={contentData.endDate}
                onChange={(date) => this.handleDateChange('endDate', date)}
              />
            </div>
          </div>
        </Modal>
        {isUploadModalOpen && <UploadFileModal onClose={this.closeUploadModal} onFileSelect={this.handleFileSelect} />}
        {isFeedbackModalOpen && (
          <ModalFeedback
            type={feedbackType}
            customText={feedbackMessage}
            onClose={this.closeFeedbackModal}
          />
        )}
        {isInviteModalOpen && (
          <ModalInviteGroup
            guestEmails={guestEmails}
            onSubmit={this.handleInviteSubmit}
            onClose={this.closeInviteModal}
          />
        )}
        {isHomeUserTreeModalOpen && (
          <HomeUserTreeModal
            onSubmit={(selectedHomes, selectedUsers) => this.submitHomeUserTreeModal(selectedHomes, selectedUsers)}
            onClose={this.closeHomeUserTreeModal}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(CreateContent);
