import React, { Component } from "react";
import Avatar from "../avatar/avatar.component";
import { withTranslation } from 'react-i18next';
import { getUserTypeTranslation } from '../../utils/translation.util.js';
import { Icon } from '@iconify/react';
import styles from './styles/contactDetail.module.scss';
import HistoryComponent from "./history/history.component.jsx";
import { createCall } from '../../services/VideoCallService';
import { playRingtone } from '../../utils/videocall.util.js';
import { getImage } from '../../services/BlobService';
import Modal from "../modal/modal.component.jsx";
import {connect} from "react-redux";
import {setVideocallInfo} from "../../redux/slices/videocallSlice";
import {useNavigate} from "react-router-dom";
import {changeUserStateToStartCall} from "../../services/UserStateService";
import {setUserStatus} from "../../redux/slices/userSlice";

class ContactDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: null,
            showUserNotAvaliableModal: false
        };
    }

    fetchImage = async (imageId) => {
        if (imageId) {
            try {
                const url = await getImage(imageId);
                this.setState({ imageSrc: url });
            } catch (error) {
                console.error('Error fetching image:', error);
                this.setState({ imageSrc: null });
            }
        } else {
            this.setState({ imageSrc: null });
        }
    };

    componentDidMount() {
        const { contact } = this.props;
        this.fetchImage(contact.avatar);
    }

    componentDidUpdate(prevProps) {
        if (this.props.contact !== prevProps.contact) {
            this.fetchImage(this.props.contact.avatar);
        }
    }

    handleCloseUserNotAvaliableModal = () => {
        this.setState({ showUserNotAvaliableModal: false });
    };


    handleCall = async (contact) => {
        if (contact.state !== 'ONLINE') {
            this.setState({ showUserNotAvaliableModal: true });
        } else {
            createCall(contact)
                .then(callResponse => {
                        this.props.setVideocallInfo(callResponse);
                        changeUserStateToStartCall(this.props.userId);
                        console.debug(callResponse);
                        playRingtone(true);
                        this.props.navigate('/videocall');
                    }
                )
                .catch(error => console.error('Error inicializando llamada', error));
        }
    };

    render() {
        const { contact, t, showHistory } = this.props;
        const { imageSrc, showUserNotAvaliableModal: showUserNotAvalaibleModal } = this.state;

        console.log("show  :  " + showHistory);
        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <Avatar image={imageSrc} name={contact.name} status={contact.state}
                        backgroundColor={contact.backgroundColor} size='avatar-very-hard' />
                    <h2 className={styles.role}>{getUserTypeTranslation(t, contact.userType)}</h2>
                    <h1 className={styles.name}>{contact.name} {contact.surname}</h1>
                    <span className={styles.email}>{contact.email}</span>
                    <button className={styles.videocallButton} onClick={() => this.handleCall(contact)}>
                        <Icon icon="fluent:video-24-filled" width="20" height="20" />
                        <span className={styles.videocallText}>{t("CONTACT_DETAIL.BUTTON.Videocall")}</span>
                    </button>
                    <div className={styles.miscSection}>
                        <div className={styles.niu}>{contact.homes[0].niu}</div>
                        <Icon icon="tabler:point-filled" className={styles.infoIcon} width="15" height="15" />
                        <div className={styles.homeName}>{contact.homes[0].name}</div>
                    </div>
                </div>
                {showHistory && <HistoryComponent contact={contact} />}
                {showUserNotAvalaibleModal && (
                    <Modal
                        title={`${contact.name}  ${t("CONTACT_DETAIL.LABEL.userNotAllowTitle")}`}
                        titleClassName={styles.modalTitle}
                        children={t("CONTACT_DETAIL.LABEL.userNotAllowContent")}
                        avatar={<Avatar image={imageSrc} name={contact.name} backgroundColor={contact.backgroundColor}
                            size='avatar-hard' />}
                        onClose={this.handleCloseUserNotAvaliableModal}
                        buttons={
                            [{
                                label: t("CONTACT_DETAIL.BUTTON.success"),
                                className: styles.modalAcceptButton,
                                onClick: this.handleCloseUserNotAvaliableModal
                            }]
                        }
                    />)}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    language: state.language.language,
    userId: state.user.id
});

const mapDispatchToProps = (dispatch) => ({
    setVideocallInfo: (videocall) => dispatch(setVideocallInfo(videocall)),
    setUserStatus: (status) => dispatch(setUserStatus(status))
});

function ContactDetailWithNavigate(props) {
    const navigate = useNavigate();
    return <ContactDetail {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactDetailWithNavigate));
