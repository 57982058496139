import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { store } from "../../redux/store";
import { setSelectedComponent, setSelectedLayout } from '../../redux/slices/layoutSlice';
import ContactDetail from '../contact-detail/contactDetail.component';
import EmptyContent from '../empty-content/empty-content.component';

const ContactsWrapper = () => {
    const selectedContact = useSelector(state => state.contact.selectedContact);
    const initLayout = () => {
        store.dispatch(setSelectedComponent('contact'));
        store.dispatch(setSelectedLayout('horizontal'));
    };
    useEffect(() => {
        initLayout();
    }, []);
    return selectedContact ? <ContactDetail contact={selectedContact} showHistory={true} /> : <EmptyContent view="contact" />;
};

export default ContactsWrapper;
